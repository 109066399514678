import React from "react"
import Loadable from "react-loadable"
import "styles/global.scss"


const loader = () => <div>Loading.</div>

const DevLazy = Loadable({
    loader: () => import("containers/Dev"),
    loading: loader,
  })

const Page = () => {
    return (
        
        <DevLazy />
    )
}

export default Page;